import React from "react";
import styled from "styled-components";
import * as Constants from "../config/Constants";

const PageContainer = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
`;

const Section = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 60px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
`;

const SubTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
  color: #666;
`;

const SuccessStories = styled.div`
  background-color: ${Constants.NavigationBackgroundColor};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 40px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Story = styled.div`
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  color: white;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  position: relative;
  background: none;

  &:before {
    content: '"';
    font-size: 40px;
    position: absolute;
    left: -30px;
    top: -30px;
    color: white;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
    text-align: center;
    padding: 20px;
    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const UserName = styled.p`
  font-size: 14px;
  margin-top: 10px;
  font-style: italic;
`;

const AdvantageSection = styled.div`
  background-color: #f0f0f0;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin-bottom: 60px;
  color: #333;
`;

const AdvantageItem = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;

const AboutPage = () => {
  return (
    <PageContainer>

      <Section>
        <Title>Welcome to Our Platform</Title>
        <Text>
        Prometric develops, delivers, and licenses in partnership with other organizations English proficiency tests, including: the Canadian English Language Proficiency Index Program (CELPIP) Test and the  Canadian Academic English Language (CAEL) Test. It also develops, delivers, and licenses in partnership with other organizations online and print self-study materials, test preparation programs, and test delivery and registration software.
        </Text>
      </Section>

      <AdvantageSection>
        <SubTitle>Our Advantages</SubTitle>
        <AdvantageItem>✔ Flexibility and Convenience: Online learning allows learners to access courses and materials from anywhere at any time, enabling them to balance their education with other personal and professional responsibilities.</AdvantageItem>
        <AdvantageItem>✔ Wide Range of Resources: Online platforms offer diverse learning materials, including videos, quizzes, interactive content, and forums, providing multiple ways for students to engage and understand the content.</AdvantageItem>
        <AdvantageItem>✔ Cost-Effectiveness: Many online learning platforms are more affordable than traditional education methods. They eliminate costs associated with commuting, accommodation, and printed materials, making education more accessible.</AdvantageItem>
        <AdvantageItem>✔ Personalized Learning Experience: Online platforms often incorporate AI or data analytics to track student progress and provide personalized feedback, allowing learners to progress at their own pace and receive content tailored to their needs.</AdvantageItem>
      </AdvantageSection>

      <SuccessStories>
        <Story>
          When I took CELPIP, I found it was like speaking English in real life.
          You speak every day with your boss and with your friends, and the CELPIP Test represents those
          everyday, real-life language situations.
          <UserName>- Rafaela B., CELPIP Test Taker</UserName>
        </Story>

        <Story>
          I had taken other English language proficiency tests before, and CELPIP was more relatable to me.
          All of the questions were situations I was familiar with from daily life, and were like conversations
          I had experienced personally.
          <UserName>- Chrisna D., CELPIP Test Taker</UserName>
        </Story>
      </SuccessStories>
    </PageContainer>
  );
};

export default AboutPage;

