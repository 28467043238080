import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: { user: null },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = null;
    },
    updateUser: (state, action) => {
      if (state.value) {
        state.value = {
          ...state.value,
          ...action.payload, // Merge the new data with the existing user data
        };
      }
    }
  },
});

export const { login, logout, updateUser } = userSlice.actions;
export const selectUser = (state) => state.user.value;

export default userSlice.reducer;
