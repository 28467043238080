import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, updateUser } from "../store/userSlice";
import * as Constants from "../config/Constants";
import defaultLogo from "../logo192.png"; // Default logo or placeholder
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MessageWindow = ({ message, error, showMessage, onClose }) => {
  return (
    <div
      style={{
        display: showMessage ? "block" : "none",
        position: "fixed",
        top: "20px",
        right: "20px",
        padding: "10px",
        backgroundColor: error ? "#f8d7da" : "#d4edda",
        color: error ? "#721c24" : "#155724",
        border: "1px solid",
        borderColor: error ? "#f5c6cb" : "#c3e6cb",
        borderRadius: "5px",
        zIndex: 1000,
      }}
    >
      <p>{message}</p>
      <button
        onClick={onClose}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "inherit",
          textDecoration: "underline",
        }}
      >
        Close
      </button>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f7f7f7;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionLeft = styled.div`
  flex: 1;
  max-width: 300px;
  margin: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SectionRight = styled.div`
  flex: 2;
  max-width: 600px;
  margin: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  flex-wrap: wrap;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  margin-bottom: 12px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: 0.5s;
  &:focus {
    outline: none !important;
    border: 3px solid #479791;
  }
`;

const HalfInput = styled.div`
  width: 48%;
`;

const HalfInputField = styled(Input)`
  width: 100%;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  background-color: #479791;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #367a68;
  }
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const RadioGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;

const RadioButton = styled.input`
  margin-right: 5px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
  &:hover::after {
    content: "Change Picture";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #479791;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    pointer-events: none;
  }
`;

const AvatarImage = styled.img`
  width: 50%;
  height: 50%;
  border-radius: 50%;
  transition: 0.3s;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    filter: blur(2px);
  }
`;

const ProfilePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email);
  const [birthDate, setBirthDate] = useState(new Date(user.student.birthDate));
  const [firstName, setFirstName] = useState(user.student.firstName);
  const [lastName, setLastName] = useState(user.student.lastName);
  const [gender, setGender] = useState(user.student.gender);
  const [homeAddress, setHomeAddress] = useState(user.student.homeAddress);
  const [city, setCity] = useState(user.student.city);
  const [province, setProvince] = useState(user.student.province);
  const [country, setCountry] = useState(user.student.country);
  const [postalCode, setPostalCode] = useState(user.student.postalCode);
  const [homePhone, setHomePhone] = useState(user.student.homePhone);
  const [cellPhone, setCellPhone] = useState(user.student.cellPhone);
  const [password, setPassword] = useState("********");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const PATH_PREFIX_AVATAR =
    process.env.REACT_APP_EDU_STORAGE_URL + Constants.PATH_PREFIX_DEFAULT;

  useEffect(() => {
    console.log("user get updated to:", user);
  }, [user]);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= 1048576 &&
      /image\/(jpg|jpeg|png|gif|tiff|webp)/.test(file.type)
    ) {
      const formData = new FormData();
      formData.append("file", file);

      const path = `upload/sys/${user.organization.orgNum}/default`;
      const dfname = `${user.student.studentNum}-avatar-${new Date()
        .toISOString()
        .replace(/[:-]/g, "")
        .replace(/\..+/, "")}.${file.type.split("/")[1]}`;
      axios
        .post(
          `${EDU_API_BASE_URL}admin/upload/avatar?uid=${user.userId}&senum=${
            user.student.studentNum
          }&path=${encodeURIComponent(path)}&dfname=${dfname}`,
          formData,
          {
            headers: {
              "Content-type":
                "multipart/form-data; boundary=<calculated when request is sent></calculated>",
              "x-token-subject": "admin1@lnova.com",
              "x-lang": "en",
              "x-channel": "WEB_ADMIN",
            },
          }
        )
        .then((response) => {
          const updatedUser = {
            ...user,
            avatarImage: response.data.data[0].fileName,
          };
          dispatch(updateUser(updatedUser));

          setMessage("Avatar updated successfully!");
          setError(false);
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
          }, 5000);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);

          setMessage("Error uploading avatar. Please try again.");
          setError(true);
          setShowMessage(true);

          setTimeout(() => {
            setShowMessage(false);
          }, 5000);
        });
    } else {
      alert(
        "Invalid file type or size. Please upload an image file less than 1MB."
      );
    }
  };

  const handleResetPassword = () => {
    axios
      .put(
        `${EDU_API_BASE_URL}user/profile/password`,
        { userId: user.userId, password: password },
        {
          headers: {
            "x-token-subject": "admin1@lnova.com",
            "x-lang": "en",
            "x-channel": "WEB_ADMIN",
          },
        }
      )
      .then((response) => {
        console.log("Password updated successfully:", response.data);

        setMessage("Password updated successfully!");
        setError(false);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error updating password:", error);

        setMessage("Error updating password. Please try again.");
        setError(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      });
  };

  const handleUpdateUsername = () => {
    axios
      .put(
        `${EDU_API_BASE_URL}user/profile/username`,
        { userId: user.userId, userName: username },
        {
          headers: {
            "x-token-subject": "admin1@lnova.com",
            "x-lang": "en",
            "x-channel": "WEB_ADMIN",
          },
        }
      )
      .then((response) => {
        console.log("Username updated successfully:", response.data);
        const updatedUser = {
          ...user,
          username: username,
        };
        dispatch(updateUser(updatedUser));

        setMessage("Username updated successfully!");
        setError(false);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error updating username:", error);

        setMessage("Error updating username. Please try again.");
        setError(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      });
  };

  const handleUpdateEmail = () => {
    axios
      .put(
        `${EDU_API_BASE_URL}user/profile/email`,
        { userId: user.userId, email: email },
        {
          headers: {
            "x-token-subject": "admin1@lnova.com",
            "x-lang": "en",
            "x-channel": "WEB_ADMIN",
          },
        }
      )
      .then((response) => {
        console.log("Email updated successfully:", response.data);
        const updatedUser = {
          ...user,
          email: email,
        };
        dispatch(updateUser(updatedUser));

        setMessage("Email updated successfully!");
        setError(false);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error updating email:", error);

        setMessage("Error updating email. Please try again.");
        setError(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      });
  };

  const handleUpdatePersonalInformation = (e) => {
    e.preventDefault();
    axios
      .post(
        `${EDU_API_BASE_URL}user/student`,
        {
          user: { userId: user.userId },
          studentId: user.student.studentId,
          studentNum: user.student.studentNum,
          firstName,
          lastName,
          gender,
          birthDate,
          homeAddress,
          city,
          province,
          country,
          postalCode,
          homePhone,
          cellPhone,
        },
        {
          headers: {
            "x-token-subject": "admin1@lnova.com",
            "x-lang": "en",
            "x-channel": "WEB_ADMIN",
          },
        }
      )
      .then((response) => {
        console.log(
          "Personal information updated successfully:",
          response.data
        );
        const updatedUser = {
          ...user,
          student: {
            ...user.student,
            firstName,
            lastName,
            gender,
            birthDate,
            homeAddress,
            city,
            province,
            country,
            postalCode,
            homePhone,
            cellPhone,
          },
        };
        dispatch(updateUser(updatedUser));

        setMessage("Personal information updated successfully!");
        setError(false);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error updating personal information:", error);

        setMessage("Error updating personal information. Please try again.");
        setError(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      });
  };

  return (
    <Container>
      <Content>
        <MessageWindow
          message={message}
          error={error}
          showMessage={showMessage}
          onClose={() => setShowMessage(false)}
        />
        <SectionLeft>
          <Header>User Information</Header>
          <Form>
            <AvatarContainer onClick={handleAvatarClick}>
              <AvatarImage
                src={PATH_PREFIX_AVATAR + user.avatarImage}
                alt="User Avatar"
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </AvatarContainer>

            <Label>User Name</Label>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button type="button" onClick={handleUpdateUsername}>
              Update User Name
            </Button>

            <Label>Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="button" onClick={handleUpdateEmail}>
              Update Email
            </Button>

            <Label>Password</Label>
            <Input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="button" onClick={handleResetPassword}>
              Update Password
            </Button>
          </Form>
        </SectionLeft>

        <SectionRight>
          <Header>Personal Information</Header>
          <Form onSubmit={handleUpdatePersonalInformation}>
            <FormRow>
              <HalfInput>
                <Label>First Name</Label>
                <HalfInputField
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </HalfInput>
              <HalfInput>
                <Label>Last Name</Label>
                <HalfInputField
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </HalfInput>
            </FormRow>

            <FormRow>
              <HalfInput>
                <Label>Gender</Label>
                <RadioGroup>
                  <RadioLabel>
                    <RadioButton
                      type="radio"
                      name="gender"
                      value="1"
                      checked={gender === 1}
                      onChange={(e) => setGender(1)}
                    />
                    Male
                  </RadioLabel>
                  <RadioLabel>
                    <RadioButton
                      type="radio"
                      name="gender"
                      value="2"
                      checked={gender === 2}
                      onChange={(e) => setGender(2)}
                    />
                    Female
                  </RadioLabel>
                  <RadioLabel>
                    <RadioButton
                      type="radio"
                      name="gender"
                      value="3"
                      checked={gender === 3}
                      onChange={(e) => setGender(3)}
                    />
                    Other
                  </RadioLabel>
                </RadioGroup>
              </HalfInput>
              <HalfInput>
                <Label>Birth Date</Label>
                <DatePicker
                  selected={birthDate}
                  onChange={(date) => setBirthDate(date)}
                  className="react-datepicker__input-container"
                />
              </HalfInput>
            </FormRow>

            <Label>Home Address</Label>
            <Input
              type="text"
              value={homeAddress}
              onChange={(e) => setHomeAddress(e.target.value)}
            />

            <FormRow>
              <HalfInput>
                <Label>City</Label>
                <HalfInputField
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </HalfInput>
              <HalfInput>
                <Label>Province</Label>
                <HalfInputField
                  type="text"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                />
              </HalfInput>
            </FormRow>
            <FormRow>
              <HalfInput>
                <Label>Country</Label>
                <HalfInputField
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </HalfInput>
              <HalfInput>
                <Label>Postal Code</Label>
                <HalfInputField
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </HalfInput>
            </FormRow>

            <FormRow>
              <HalfInput>
                <Label>Home Phone</Label>
                <HalfInputField
                  type="text"
                  value={homePhone}
                  onChange={(e) => setHomePhone(e.target.value)}
                />
              </HalfInput>
              <HalfInput>
                <Label>Cell Phone</Label>
                <HalfInputField
                  type="text"
                  value={cellPhone}
                  onChange={(e) => setCellPhone(e.target.value)}
                />
              </HalfInput>
            </FormRow>

            <Button type="submit">Update Personal Information</Button>
          </Form>
        </SectionRight>
      </Content>
    </Container>
  );
};

export default ProfilePage;
