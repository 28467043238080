import "./App.css";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import ErrorPage from "./components/ErrorPage";
import CourseDetail from "./pages/CourseDetail";
import LessonDetail from "./pages/LessonDetail";
import LessonDetailTh from "./pages/LessonDetailTh";
import MyHomework from "./pages/MyHomework";
import MyHomeworkTh from "./pages/MyHomeworkTh";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import StudentCourse from "./pages/StudentCourse";
import TeacherCourse from "./pages/TeacherCourse";
import StudentInfo from "./pages/StudentInfo";
import Profile from "./components/common/ProfileCard";
import SearchResults from "./pages/SearchResults";
import ForgetPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ProfilePage from "./pages/Profile"

function App() {
  return (
    <div className="App">
      <Header />
      <NavBar />
      <Routes>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />

        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgotpass" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/student-course" element={<StudentCourse />} />
        <Route path="/teacher-course" element={<TeacherCourse />} />
        <Route path="/student-info" element={<StudentInfo />} />
        <Route path="/course-detail/:courseId" element={<CourseDetail />} />
        <Route path="/lesson-detail-st/:lessonId" element={<LessonDetail />} />
        <Route path="/lesson-detail-th/:lessonId" element={<LessonDetailTh />} />
        <Route path="/homework-st/:homeworkId" element={<MyHomework />} />
        <Route path="/homework-th/:studentId" element={<MyHomeworkTh />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/forgot-password/email" element={<ForgetPassword />} />
        <Route path="/forgot-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/profile-page" element={<ProfilePage/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
