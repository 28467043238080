import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Constants from "../config/Constants";
import { useSelector } from "react-redux";
import Message from "../components/common/Message";
import { selectLanguage } from "../store/languageSlice";
import { selectCourse } from "../store/courseSlice";
import { selectLesson } from "../store/lessonSlice";
import { selectUser } from "../store/userSlice";
import { selectTeacher } from "../store/teacherSlice";
import { selectClassmate } from "../store/classmateSlice";
import "../assets/css/formInput.css";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import NavigatorBar from "../components/common/NavigatorBar";
import { mobile } from "../assets/css/responsive";
import TeacherDisplay from "../components/common/TeacherDisplay";
import StudentDisplay from "../components/common/StudentDisplay";
import DateList from "../components/common/DateList";
import {
  Container,
  Left,
  MainBody,
  AdsBody,
  Right,
  FrameBorder,
  Navigator,
  Title,
  Name,
  Button,
  HomeworkDesc,
} from "../components/common/Mainstyle";

const UploadSize = styled.div`
  font-size: 11px;
  width: 110px;
  height: 23px;
  display: ${(props) => (props.hide ? "none" : "block")};
`;
const MaxWords = styled.p`
  font-size: 11px;
  color: #666666;
  height: 21px;
  line-height: 21px;
`;
const FileRow = styled.div`
  margin: 0 10% 2% 10%;
  display: flex;
  justify-content: space-between;
  ${mobile({ margin: "10px 10% 10px 6%" })};
`;
const SaveFile = styled.div`
  display: flex;
  margin: 3% 10% 3% 10%;
  justify-content: space-between;
`;
const FileName = styled.div`
  border: none;
  height: 33px;
  padding-top: 5px;
  font-size: 13px;
  color: #666666;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const FileImg = styled.div`
  border: none;
  width: 25%;
`;
const Img = styled.img`
  border: 1px solid gray;
  width: 100%;
  height: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MyHomework = () => {
  const getLanguage = useSelector(selectLanguage);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const course = useSelector(selectCourse);
  const teachers = useSelector(selectTeacher);
  const lesson = useSelector(selectLesson);
  const classmates = useSelector(selectClassmate);
  const studentNum = user ? user.student.studentNum : "";
  const { homeworkId } = useParams();
  
  const EDU_ORG_NUM = process.env.REACT_APP_EDU_ORG_NUM;
  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;
  const EDU_STORAGE_URL = process.env.REACT_APP_EDU_STORAGE_URL;
  const AXIOS_CONFIG = {...Constants.AXIOS_CONFIG_BASE};
  AXIOS_CONFIG.headers["x-token-subject"] = user.email;

  const [file, setFile] = useState("");
  const [homeworkFiles, setHomeworkFiles] = useState([]);
  const [homeworkData, setHomeworkData] = useState({});
  const [homeworkDescription, setHomeworkDescription] = useState("");
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [message, setMesasge] = useState("");
  const [show, setShow] = useState(false);
  const [pic, setPic] = useState(null);
  const [fileNumber, setFileNumber] = useState(0);
  
  const year = "" + new Date(course.startDate).getUTCFullYear();
  const month = ("0" + ((new Date(course.startDate)).getUTCMonth() + 1)).slice(-2);
  const yearMonth = year.substring(2) + month;
  
  const NavigatorHtml = () => {
    return (
      <Navigator>
        <Link underline="hover" color="inherit" href="/">
          {t("home_breadcrumb")}
        </Link>/
        <Link underline="hover" color="inherit" href="/student-course/">
          {t("studentCourse")}
        </Link>/
        <Link
          underline="hover"
          color="inherit"
          href={"/course-detail/" + course.courseId}
        >
          {t("course_detail")}
        </Link>/
        <Link
          underline="hover"
          color="inherit"
          href={"/lesson-detail-st/" + lesson.lessonId}
        >
          {t("lesson_detail")}
        </Link>/
        <Link underline="none" color="text.primary" aria-current="page">
          {t("myHomework")}
        </Link>
      </Navigator>
    );
  };

  const fetchHomeworkFilesByhomeworkId = () => {
    axios
      .get(EDU_API_BASE_URL + "homework/" + homeworkId + "/files", AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setFileNumber(response.data.data.length);
          setHomeworkFiles(response.data.data);

          console.log("homework-files-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchHomeworkFilesByhomeworkId"]);
        console.log("homework-files-error", error);
      });
  };
  const fetchHomeworkByhomeworkId = () => {
    axios
      .get(EDU_API_BASE_URL + "homework/" + homeworkId, AXIOS_CONFIG)
      .then((response) => {
        if (response.status >= 200 && response.status < 299) {
          setHomeworkData(response.data.data[0]);
          setHomeworkDescription(response.data.data[0].description);
          console.log("homework-by-id-res", response.data.data);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setErrors([...errors, error.message + ": fetchHomeworkByhomeworkId"]);
        console.log("homework-by-id-res", error);
      });
  };

  const deleteFile = (fileId, fileName, filePath) => {
    window.confirm(t("deleteComfirm"))
      ? comfirmDelete(fileId, fileName, filePath)
      : cancelDelete();
  };

  const comfirmDelete = (fileId, fileName, filePath) => {
    axios
      .delete(
        EDU_API_BASE_URL +
          "homework/file/remove?fid=" +
          fileId +
          "&file=" +
          fileName +
          "&path=" +
          filePath,
          AXIOS_CONFIG
      )
      .then((response) => {
        console.log("remove-hw-file-res", response.data);
        fetchHomeworkFilesByhomeworkId();
      })
      .catch((error) => {
        console.log("remove-hw-file error", error);
      });
  };

  const cancelDelete = () => {};

  const saveFile = (homeworkDescription) => {
    const value = {
      description: homeworkDescription,
      homeworkId: homeworkId,
      lessonId: homeworkData.lessonId,
      studentId: homeworkData.studentId,
      teacherId: homeworkData.teacherId,
      tcomments: homeworkData.tcomments,
      teacherConfirmed: homeworkData.teacherConfirmed,
    };

    axios
      .post(EDU_API_BASE_URL + "homework", value, AXIOS_CONFIG)
      .then((response) => {
        console.log("homework-post-res", response.data);
        setMesasge(t("saveSuccess"));
        setError("");
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();

        fetchHomeworkByhomeworkId();
      })
      .catch((error) => {
        setMesasge(t("saveError"));
        setError(error);
        setShow(true);
        const timeId = () =>
          setTimeout(() => {
            setShow(false);
          }, 5000);
        timeId();
        console.log("homework-post error", error);
      });
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    if (!user) navigate("/login");

    if (errors.length === 0) {
      fetchHomeworkByhomeworkId();
      fetchHomeworkFilesByhomeworkId();
    }
  }, [fileNumber]);

  const uploadFile = (courseNum) => {
    window.confirm(t("uploadComfirm"))
      ? comfirmUpload(courseNum)
      : cancelUpload();
  };

  const comfirmUpload = (courseNum) => {
    const formData = new FormData();
    // Update the formData object
    formData.append("file", file);
    console.log("upload-file-req", formData);

    axios
      .post(
        EDU_API_BASE_URL +
          "homework/file/upload?lid=" +
          lesson.lessonId +
          "&hid=" +
          homeworkId +
          "&path=upload/" +
          yearMonth +
          "/" + EDU_ORG_NUM + "/" + //O220101000000
          courseNum +
          "/" +
          studentNum,
        formData,
        AXIOS_CONFIG
      )
      .then((response) => {
        console.log("hw-file-upload-res", response.data);
        setFile("");
        setMesasge("Data uploaded successfully!");
        setError("");
        fetchHomeworkFilesByhomeworkId();
      })
      .catch((error) => {
        setMesasge("Error, Data has not been upload!!");
        setError(error);
        console.log("hw-file-upload error", error);
      });
  };

  const cancelUpload = () => {
    setFile(null);
  };

  const onChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (fileExtension === "exe") {
        setMesasge("Invalid file type! Executable files are not allowed.");
        setError("Executable files are not allowed");
        setShow(true);
        setTimeout(() => setShow(false), 5000);
        setFile("");
        return;
    }

    console.log("on0change-file", selectedFile);
    setFile(selectedFile);
  };

  const handleChange = (e) => {
    setHomeworkDescription(e.target.value);
  };

  if (errors.length > 0) {
    return (
      <div>
        {errors.map((error) => (
          <div>{error}</div>
        ))}
      </div>
    );
  } else {
    return (
      <Container>
        <Left />
        <MainBody>
          {show ? <Message message={message} error={error} /> : ""}
          <NavigatorBar content={true} navigatorHtml={NavigatorHtml} />
          <FrameBorder>
            {course ? (
              <Title>
                <Name>
                  {t("current-homework")} ({course.courseName})
                </Name>
                <DateList lesson={lesson} />
              </Title>
            ) : (
              ""
            )}
            {homeworkFiles
              ? homeworkFiles.map((file) => (
                  <FileRow key={file.fileId}>
                    <a
                      href={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                      target="_blank"
                      onMouseOver={() => setPic(file.fileId)}
                      onMouseLeave={() => setPic(null)}
                      download
                    >
                      <FileName>{file.fileName} </FileName>
                    </a>
                    {pic === file.fileId &&
                    (file.fileType === "jpg" || file.fileType === "png") ? (
                      <FileImg>
                        <Img
                          src={EDU_STORAGE_URL + file.filePath + "/" + file.fileName}
                        />
                      </FileImg>
                    ) : ("")}
                    <Button
                      hide={homeworkData.teacherConfirmed}
                      onClick={() =>deleteFile(file.fileId, file.fileName, file.filePath)}
                    >
                      {" "}
                      {t("deleteFile")}{" "}
                    </Button>
                  </FileRow>
                ))
              : ""}
            <FileRow>
              <label
                className={homeworkData.teacherConfirmed ? "" : "custom-file-upload"}
              >
                <input
                  type="file"
                  disabled={homeworkData.teacherConfirmed}
                  multiple
                  onChange={onChange}
                />
                {homeworkData.teacherConfirmed ? "" : t("chooseFile")}
              </label>
              <FileName> {file ? file.name : ""} </FileName>
              <FileName>
                <Button
                  hide={!file || homeworkData.teacherConfirmed}
                  disabled={!file}
                  onClick={() => uploadFile(course.courseNum)}
                >
                  {" "}
                  {t("uploadFile")}{" "}
                </Button>
                <UploadSize hide={!file || homeworkData.teacherConfirmed}>
                  {" "}
                  {t("uploadSize")}{" "}
                </UploadSize>{" "}
              </FileName>
            </FileRow>
            <HomeworkDesc>
              {t("homeworkDesc")}
              <MaxWords>&nbsp; ({t("maxWords")}) </MaxWords>
            </HomeworkDesc>
            <textarea
              value={homeworkDescription}
              rows={6}
              maxrows={500}
              readOnly={homeworkData.teacherConfirmed}
              onChange={handleChange}
            />
            <SaveFile>
              <FileName> </FileName>
              <Button
                hide={homeworkData.teacherConfirmed}
                onClick={() => saveFile(homeworkDescription)}
              >
                {" "}{t("save")}{" "}
              </Button>
            </SaveFile>
            <HomeworkDesc>{t("teacherComment")}</HomeworkDesc>
            <textarea
              value={homeworkData.tcomments ? homeworkData.tcomments : ""}
              rows={6}
              maxrows={500}
              readOnly={true}
            />
            <SaveFile> </SaveFile>
          </FrameBorder>
        </MainBody>
        <AdsBody>
          <NavigatorBar content={false} navigatorHtml={NavigatorHtml} />
          <TeacherDisplay teachers={teachers} role={"myTeacher"} />
          {user ? (
            <StudentDisplay students={classmates} role={"myClassmate"} />
          ) : (
            ""
          )}
        </AdsBody>
        <Right />
      </Container>
    );
  }
};

export default MyHomework;
