import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import FormInput from "../components/common/FormInput";
import {
  Container,
  Form,
  Title,
  Button,
} from "../components/common/LoginRegister";
import * as Constants from "../config/Constants";

const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const ResetPassword = () => {
  const { t } = useTranslation();
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === "" || repeatPassword === "") {
      setErrors([{ message: "Don't leave fields empty" }]);
      return;
    }
    if (password !== repeatPassword) {
      setErrors([{ message: "Passwords do not match" }]);
      return;
    }
    await callResetPasswordApi();
  };
  const callResetPasswordApi = async () => {
    try {
      await axios.put(
        `${EDU_API_BASE_URL}user/profile/password`,
        { userId: uid, password: password },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "x-channel": "WEB_EDU",
            "x-lang": "en",
          },
        }
      );
      setPosted(true);
      setErrors([
        { message: "Password has been reset successfully!", success: true },
      ]);
      navigate("/login");
    } catch (error) {
      setPosted(false);
      setErrors([{ message: error.message, success: false }]);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>{t("Rest Password")}</Title>
        <FormInput
          name="password"
          type="password"
          placeholder="New Password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          pattern="^[a-zA-Z0-9!@#$%^&*()_+=?]{8,16}$"
          validateErr="Password is between 8-16 characters"
        />
        <FormInput
          name="repeatPassword"
          type="password"
          placeholder="Repeat New Password"
          label="Repeat Password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          pattern="^[a-zA-Z0-9!@#$%^&*()_+=?]{8,16}$"
          validateErr="Password is between 8-16 characters"
        />
        <Button type="submit">{t("CONFIRM")}</Button>
        {errors.map((error) => (
          <Error key={error.message} success={error.success}>
            {error.message}
          </Error>
        ))}
      </Form>
    </Container>
  );
};

export default ResetPassword;
