import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import FormInput from "../components/common/FormInput";
import {
  Container,
  Form,
  Title,
  Button,
} from "../components/common/LoginRegister";
import * as Constants from "../config/Constants";

const Error = styled.div`
  font-size: 12px;
  color: ${(props) => (props.success ? "green" : "red")};
  margin-left: 10px;
  display: block;
  text-align: left;
`;

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const EDU_API_BASE_URL = process.env.REACT_APP_EDU_API_BASE_URL;

  const [email, setEmail] = useState("");
  const [posted, setPosted] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setPosted(false);
      setErrors([{ message: "Don't leave fields empty" }]);
      return;
    }
    await callResetPasswordApi();
  };

  const callResetPasswordApi = async () => {
    try {
      // Check if email exists
      const emailExistsResponse = await axios.get(
        `${EDU_API_BASE_URL}user/search?skey=STU_EMAIL&svalue=${email}`,
        {
          headers: {
            "x-channel": "WEB_ADMIN",
            "x-token-subject": "admin1@lnova.com",
          },
        }
      );
      if (emailExistsResponse.data.data.length === 0) {
        setErrors([{ message: "Email does not exist", success: false }]);
        return;
      }
      const uid = emailExistsResponse.data["data"][0]["userId"];

      // Get token for this email
      const tokenResponse = await axios.post(
        `${EDU_API_BASE_URL}token`,
        {
          scripts: `
          if (pm.response.code === 200) {
            pm.globals.set("bearerToken", pm.response.json().access_token);
          }
        `,
        },
        {
          headers: {
            "x-token-subject": email,
            "x-developer-id": "002",
          },
        }
      );
      
      // Send the reset link to the email
      await axios({
        method: 'post',
        url: `${EDU_API_BASE_URL}forgot-password/${email}`,
        headers: {
          "x-channel": "WEB_EDU"
        }
      });

      // // the reset link
      // const domain = "WEB_EDU";
      // const token = tokenResponse.data.access_token;
      // const resetLink = `${domain}/forgot-password/${uid}/${token}`;

      setPosted(true);
      setErrors([
        { message: "Reset link has been sent to your email", success: true },
      ]);
    } catch (error) {
      setPosted(false);
      setErrors([{ message: error.message, success: false }]);
    }
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>{t("Enter Your Email")}</Title>
        <FormInput
          name="email"
          type="email"
          placeholder="Email"
          label="Email"
          value={email}
          onChange={onChange}
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
          validateErr="invalid email format"
        />
        <Button type="submit">{t("SEND RESET LINK TO EMAIL")}</Button>
        {/* <NavLink to="/login">{t("Back To Login")}</NavLink> */}
        {errors.map((error) => (
          <Error key={error.message} success={posted}>
            {error.message}
          </Error>
        ))}
      </Form>
    </Container>
  );
};

export default ForgetPassword;
