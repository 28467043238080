import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  max-width: 1200px; /* Limit the width to 1200px */
  display: flex;
  justify-content: space-between; /* Ensures even space between the two sections */
  padding: 40px 20px;
  background-color: #f9f9f9;
  margin: 0 auto; /* Center the container */
`;

const ContactContent = styled.div`
  width: 48%; /* Adjust the width to occupy less space */
  padding-right: 20px;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center; /* Align the title and content in the center vertically */
  margin-bottom: 30px;
`;

const ContactTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px; /* Adds space between title and content */
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const ContactText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin: 0; /* Remove any margin to ensure it's aligned */
`;

const PhoneLink = styled.a`
  color: #b59e5f;
  font-weight: bold;
  text-decoration: none;
  margin: 0; /* Remove margin to ensure it's aligned */
`;

const MapSection = styled.div`
  width: 48%; /* Adjust the width to occupy less space */
  iframe {
    width: 100%;
    height: 450px;
    border: none;
  }
`;

const Contact = () => {
  return (
    <PageContainer>
      <ContactContent>
        <ContactItem>
          <ContactTitle>
            {/* location icon */}
            Addresses Office:
          </ContactTitle>
          <ContactText>
            801-180 Bloor St. W. Toronto, ON M5S 2V6 Canada
          </ContactText>
        </ContactItem>

        <ContactItem>
          <ContactTitle>
            {/* phone icon */}
            Phone:
          </ContactTitle>
          <PhoneLink href="tel:+11234567890">1-(123) 456-7890</PhoneLink>
        </ContactItem>

        <ContactItem>
          <ContactTitle>
            {/* clock icon */}
            Hours:
          </ContactTitle>
          <ContactText>
            Monday to Friday: 8:30 a.m. to 8:00 p.m. 
          </ContactText>
        </ContactItem>

        <ContactItem>
          <ContactTitle>Wechat:</ContactTitle>
          <img
            src=""
            alt="Wechat QR Code"
            style={{ width: '150px' }}
          />
        </ContactItem>
      </ContactContent>

      <MapSection>
        <iframe
          title="Google Maps Location of Office" 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.807549085361!2d-79.39787072416591!3d43.668305779020204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34d7cfed0b27%3A0x1d5d2ffec9b44d7!2sRoyal%20Ontario%20Museum!5e0!3m2!1sen!2sca!4v1621352176378!5m2!1sen!2sca"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </MapSection>
    </PageContainer>
  );
};

export default Contact;
